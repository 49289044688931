// @flow

import React from 'react';
import type { Node } from 'react';
import { Link } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { analytics as ENUM_ANALYTICS } from 'Enum';
import { FixedHeightSlider } from 'shared_components/arts/slider/fixedHeight/Slider';

type Props = {
  cardDisplayStyle?: string,
  title?: string,
  subTitle?: string,
  items: Array<Object>,
  link?: {
    external: boolean,
    target?: string,
    text: string,
    href: string,
  },
  disableHeadingTags: boolean,
};

/**
 * CmsModuleArtSlider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const CmsModuleArtSlider = ({
  cardDisplayStyle,
  title = null,
  subTitle = null,
  items,
  link = null,
  disableHeadingTags = false,
}: Props): Node => {
  if (!items || !items.length) {
    return null;
  }

  return (
    <Section>
      <Wrapper>
        <SectionContent
          nodeType="section"
          title={title}
          subTitle={subTitle}
          headingProps={{ tag: 'h2' }}
          headingSubTitleProps={{ tag: 'h3' }}
          link={
            link ? (
              <Link
                to={link.href}
                external={!!link.external}
                {...(link.target ? { target: link.target } : null)}
                title={link.text}
              >
                {link.text}
              </Link>
            ) : null
          }
        >
          <FixedHeightSlider
            artDirectionSizesKey="slider.gridFixedHeight300"
            height={300}
            slideHeightConfig={{ sm: 275, md: 275, lg: 300 }}
            items={items.map(({ images, ...item }) => ({
              ...item,
              storeCode: item.store && item.store.code,
              images: images.map(({ file, ...image }) => ({ ...image, ...file })),
            }))}
            cardDisplayStyle={cardDisplayStyle || '7'}
            disableHeadingTags={disableHeadingTags}
            tracking={{
              enabled: true,
              enableInitial: false,
              enableVisibility: true,
              enableClick: true,
              name: ENUM_ANALYTICS.list.name.CMS_ART_FIXED_HEIGHT_SLIDER,
            }}
          />
        </SectionContent>
      </Wrapper>
    </Section>
  );
};
